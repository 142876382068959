import React from 'react';
import Icon from '@ant-design/icons';
import { IconProps } from './iconProps';
import { ReactComponent as RightArrowSvg } from '@/assets/icons/right_arrow.svg';
import { ReactComponent as FoldSvg } from '@/assets/menuIcons/fold.svg';
import { ReactComponent as UnFoldSvg } from '@/assets/menuIcons/un_fold.svg';
import { ReactComponent as HomeSvg } from '@/assets/menuIcons/home.svg';
import { ReactComponent as NoPermission } from '@/assets/common/no_permission.svg';
import { ReactComponent as NoPage } from '@/assets/common/no_page.svg';
import { ReactComponent as SchoolSvg } from '@/assets/menuIcons/school.svg';
import { ReactComponent as LogoSvg } from '@/assets/icons/logo.svg';

export const RightArrowIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={RightArrowSvg} />
);

export const FoldIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={FoldSvg} />
);

export const UnFoldIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={UnFoldSvg} />
);

export const HomeIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={HomeSvg} />
);

export const NoPermissionIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={NoPermission} />
);

export const NoPageIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={NoPage} />
);

export const SchoolIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={SchoolSvg} />
);

export const LogoIcon: React.FC<IconProps> = (props) => (
  <Icon {...props} component={LogoSvg} />
);
