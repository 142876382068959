import { RootState } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nameSpace, Login, UserInfo } from './service';

interface UserState {
  loginLoading: boolean;
  userInfo: UserInfo | null;
}

const initialState: UserState = {
  loginLoading: false,
  userInfo: null,
};

const UserSlice = createSlice({
  // 命名空间
  name: nameSpace,
  // 初始化状态
  initialState,
  // reducers 设置 state
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loginLoading = action.payload;
    },
  },
  extraReducers: {
    // login 状态
    [Login.fulfilled.type]: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
      state.loginLoading = false;
    },
    [Login.pending.type]: (state) => {
      state.loginLoading = true;
    },
    [Login.rejected.type]: (state) => {
      state.loginLoading = false;
    },
  },
});

export const { setLoading } = UserSlice.actions;

export const selectLoginLoading = (state: RootState): boolean =>
  state.user.loginLoading;
export const selectUserInfo = (state: RootState): UserInfo =>
  state.user.userInfo as UserInfo;

export default UserSlice.reducer;
