/** @format */

import React, { lazy } from 'react';
import {
  ProfileOutlined,
  DashboardOutlined,
  FireOutlined,
  AuditOutlined,
  CrownOutlined,
  ForkOutlined,
} from '@ant-design/icons';
import { SchoolIcon } from '@/components/Icons';

export interface Route {
  path?: string;
  component?: React.ElementType;
  children?: Route[];
  redirect?: string;
  exact?: boolean;
  strict?: boolean;
  name?: string;
  noLayout?: boolean;
  hideBreadcrumb?: boolean;
  authority?: string;
  showInMenu?: boolean;
  icon?: React.ElementType;
}

const routers: Route[] = [
  {
    path: '/',
    redirect: '/systemManagement',
    exact: true,
    strict: true,
  },
  {
    path: '/template',
    name: ' ',
    component: lazy(() => import('@pages/template')),
    exact: true,
    noLayout: true,
    hideBreadcrumb: true,
  },
  {
    path: '/login',
    name: '登录',
    component: lazy(() => import('@pages/login')),
    exact: true,
    noLayout: true,
    hideBreadcrumb: true,
  },
  {
    path: '/systemManagement',
    name: '系统管理',
    showInMenu: true,
    icon: ProfileOutlined,
    children: [
      {
        path: '/systemManagement',
        redirect: '/systemManagement/managerList',
        exact: true,
        strict: true,
      },
      {
        path: '/systemManagement/managerList',
        name: '管理员列表',
        component: lazy(() => import('@pages/managerList')),
        exact: true,
        showInMenu: true,
      },
      {
        path: '/systemManagement/systemLog',
        name: '系统日志',
        component: lazy(() => import('@pages/systemLog')),
        exact: true,
        showInMenu: true,
      },
    ],
  },
  {
    path: '/userManagement',
    name: '用户管理',
    showInMenu: true,
    icon: SchoolIcon,
    children: [
      {
        path: '/userManagement',
        redirect: '/userManagement/accountList',
        exact: true,
        strict: true,
      },
      {
        path: '/userManagement/accountList',
        name: '账户列表',
        component: lazy(() => import('@pages/account')),
        exact: true,
        showInMenu: true,
      },
    ],
  },
  {
    path: '/monitorCenter',
    name: '运动数据',
    showInMenu: true,
    icon: DashboardOutlined,
    children: [
      {
        path: '/monitorCenter',
        redirect: '/monitorCenter/dataList',
        exact: true,
        strict: true,
      },
      {
        path: '/monitorCenter/dataList',
        name: '数据列表',
        component: lazy(() => import('@pages/dataList')),
        exact: true,
        showInMenu: true,
      },
    ],
  },
  {
    path: '/SportManagement',
    name: '运动管理',
    showInMenu: true,
    icon: FireOutlined,
    children: [
      {
        path: '/SportManagement',
        redirect: '/SportManagement/sportList',
        exact: true,
        strict: true,
      },
      {
        path: '/SportManagement/sportList',
        name: '运动列表',
        component: lazy(() => import('@pages/sportList')),
        exact: true,
        showInMenu: true,
      },
    ],
  },
  {
    path: '/healthLog',
    name: '健康记录',
    showInMenu: true,
    icon: AuditOutlined,
    children: [
      {
        path: '/healthLog',
        redirect: '/healthLog/healthList',
        exact: true,
        strict: true,
      },
      {
        path: '/healthLog/healthList',
        name: '健康数据',
        component: lazy(() => import('@pages/healthList')),
        exact: true,
        showInMenu: true,
      },
    ],
  },
  {
    path: '/myAchievements',
    name: '我的成就',
    showInMenu: true,
    icon: CrownOutlined,
    children: [
      {
        path: '/myAchievements',
        redirect: '/myAchievements/achievementsList',
        exact: true,
        strict: true,
      },
      {
        path: '/myAchievements/achievementsList',
        name: '成就列表',
        component: lazy(() => import('@pages/achievementsList')),
        exact: true,
        showInMenu: true,
      },
    ],
  },
  {
    path: '/versionManagement',
    name: '版本管理',
    showInMenu: true,
    icon: ForkOutlined,
    children: [
      {
        path: '/versionManagement',
        redirect: '/versionManagement/versionList',
        exact: true,
        strict: true,
      },
      {
        path: '/versionManagement/versionList',
        name: '版本列表',
        component: lazy(() => import('@pages/versionList')),
        exact: true,
        showInMenu: true,
      },
    ],
  },
  {
    path: '/dynamicList',
    name: '动态管理',
    showInMenu: true,
    icon: FireOutlined,
    children: [
      {
        path: '/dynamicList',
        redirect: '/dynamicList/dynamicList',
        exact: true,
        strict: true,
      },
      {
        path: '/dynamicList/dynamicList',
        name: '动态列表',
        component: lazy(() => import('@pages/dynamicList')),
        exact: true,
        showInMenu: true,
        children: [
          {
            path: '/dynamicList/dynamicList/add',
            name: '文章编辑',
            component: lazy(() => import('@pages/dynamicList/add')),
            exact: true,
          },
        ],
      },
    ],
  },
  /* {
    path: '/taskManagement',
    name: '任务管理',
    showInMenu: true,
    icon: ForkOutlined,
    children: [
      {
        path: '/taskManagement',
        redirect: '/taskManagement/planTarget',
        exact: true,
        strict: true,
      },
      {
        path: '/taskManagement/planTarget',
        name: '计划目标',
        component: lazy(() => import('@pages/planTarget')),
        exact: true,
        showInMenu: true,
      },
    ],
  },*/
  {
    path: '/rankManagement',
    name: '排行管理',
    showInMenu: true,
    icon: ForkOutlined,
    children: [
      {
        path: '/rankManagement',
        redirect: '/rankManagement/scoreRank',
        exact: true,
        strict: true,
      },
      {
        path: '/rankManagement/scoreRank',
        name: '总分排行',
        component: lazy(() => import('@pages/scoreRank')),
        exact: true,
        showInMenu: true,
      },
    ],
  },
  {
    path: '/404',
    name: '404',
    component: lazy(() => import('@pages/notFound')),
    exact: true,
    hideBreadcrumb: true,
  },
  {
    path: '*',
    redirect: '/404',
    exact: true,
  },
];

export default routers;
