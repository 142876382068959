import { createAsyncThunk } from '@reduxjs/toolkit';
import { Fetch } from '@/apis/fetch';
import { BaseResponse } from '@/types/response';
import history from '@utils/history';
import md5 from 'md5';

export const nameSpace = 'user';

export interface LoginParams {
  username: string;
  password: string;
}

// 用户信息
export interface UserInfo {
  token: string;
}
// 登录
export const Login = createAsyncThunk(
  `${nameSpace}/login`,
  async (params: LoginParams): Promise<BaseResponse<UserInfo>> => {
    const response = await Fetch.post<LoginParams, BaseResponse<UserInfo>>(
      '/system/login',
      { ...params, password: md5(`${params.password}`) },
    );
    if (response?.code === 0) {
      localStorage.setItem('token', response?.result?.token || '');
      history.push('/systemManagement/managerList');
    }
    return response;
  },
);

// 用户信息
export const UserInfo = createAsyncThunk(
  `${nameSpace}/userInfo`,
  async (): Promise<BaseResponse<UserInfo>> => {
    return await Fetch.get<null, BaseResponse<UserInfo>>('/admin/info');
  },
);
