/** @format */
import BasicLayout from '@layout/BasicLayout';
import routers, { Route as IRoute } from '@routes/router';
import { Suspense } from 'react';
import { HashRouter } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';
import LoadingPage from '@components/LoadingPage';
import './App.less';

function App() {
  const flatterRouter = (routers: IRoute[]) => {
    const result: IRoute[] = [];
    for (let i = 0; i < routers.length; i++) {
      const router = routers[i];
      result.push({ ...router });
      if (router.children) {
        result.push(...flatterRouter(router.children || []));
      }
    }
    return result;
  };
  const renderRoutes = (routes: IRoute[], extraProps = {}) => {
    if (!routes || !routes.length) return null;
    const layoutRoute = routes.filter((item) => !item.noLayout);
    const noLayoutRoute = routes.filter((item) => item.noLayout);
    const hideBreadcrumbPaths = routes
      .filter((item) => item.hideBreadcrumb)
      .map((item) => item.path);

    const RouteCom = (routeData: IRoute[]) => {
      return routeData.map((route, i) => {
        if (route.redirect) {
          return (
            <Redirect
              key={i}
              path={route.path}
              exact={route.exact}
              strict={route.strict}
              to={route.redirect}
            />
          );
        }
        const Com = route.component;
        return Com ? (
          <Route
            key={route.name || i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={(props) => {
              document.title = route.name || '运动表现数智管理系统';
              return <Com {...props} {...extraProps} route={route} />;
            }}
          />
        ) : null;
      });
    };
    return (
      <HashRouter>
        <Suspense fallback={<LoadingPage />}>
          <Switch {...extraProps}>
            {/*   无菜单页面    */}
            {RouteCom(noLayoutRoute)}
            {/*   菜单布局页面    */}
            <BasicLayout hideBreadcrumbPaths={hideBreadcrumbPaths}>
              <Suspense fallback={<LoadingPage />}>
                <Switch>{RouteCom(layoutRoute)}</Switch>
              </Suspense>
            </BasicLayout>
          </Switch>
        </Suspense>
      </HashRouter>
    );
  };

  return (
    <ConfigProvider locale={zhCN}>
      {renderRoutes(flatterRouter(routers))}
    </ConfigProvider>
  );
}

export default App;
