import React from 'react';
import { useLocation, BrowserRouter, useHistory } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import Icon from '@ant-design/icons';
import routers, { Route as IRoute } from '@/routes/router';
import { ReactComponent as RightArrowSvg } from '@/assets/icons/right_arrow.svg';
import styles from './index.module.less';

const flatterRouter = (routers: IRoute[]) => {
  const result: IRoute[] = [];
  for (let i = 0; i < routers.length; i++) {
    const router = routers[i];
    result.push({ ...router });
    if (router.children) {
      result.push(...flatterRouter(router.children || []));
    }
  }
  return result;
};

const TopBreadcrumb: React.FC = () => {
  const breadcrumbNameMap: Record<string, string> = {};
  const iRouter = useHistory();

  flatterRouter(routers).forEach((item) => {
    if (item.path && item.name) {
      breadcrumbNameMap[item.path] = item.name;
    }
  });
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const breadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    if (!breadcrumbNameMap[url]) return null;
    return (
      <Breadcrumb.Item key={url} className={styles.breadcrumbItem}>
        <span className={styles.link} onClick={() => iRouter.push(url)}>
          {breadcrumbNameMap[url]}
        </span>
      </Breadcrumb.Item>
    );
  });
  return (
    <div className={styles.breadcrumb}>
      <BrowserRouter>
        <Breadcrumb
          separator={
            <Icon component={() => <RightArrowSvg fill='#899399' />} />
          }>
          {breadcrumbItems}
        </Breadcrumb>
      </BrowserRouter>
    </div>
  );
};

export default TopBreadcrumb;
