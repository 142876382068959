import * as fetch from 'portable-fetch';
import { stringify } from 'qs';
import { message } from 'antd';
import history from '@/utils/history';
import { BaseResponse } from '@/types/response';

const BASE_URL = process.env.REACT_APP_BASE_URL || window.location.origin;

/**
 * @description: 枚举出请求数据格式类型
 * @param {type} 枚举类型
 * @return:
 */
enum ContentType {
  json = 'application/json;charset=UTF-8',
  form = 'application/x-www-form-urlencoded; charset=UTF-8',
}

/**
 * @description: 枚举request请求的method方法
 * @param {type} 枚举类型
 * @return:
 */
enum FetchMethod {
  get = 'GET',
  post = 'POST',
}

const codeMessage: any = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

const checkStatus = (response: Response) => {
  // code 401 means token failure, skip the login api
  if (response?.status >= 400 && response?.status < 600) {
    return Promise.reject(codeMessage[response?.status]);
  }
  if (response?.status === 401) {
    history.push('/login');
  }
  return response;
};

const checkCode = (data: BaseResponse<any>) => {
  if (data?.code !== 0) {
    message.error(data.message || '未知错误');
    if (data.code === -200) {
      localStorage.removeItem('token');
      setTimeout(() => {
        history.replace('/login');
      }, 300);
    }
  }
  return data;
};

export class Request {
  private async getValue<T>(
    method: FetchMethod,
    fetchPath: string,
    data?: T,
  ): Promise<any> {
    let fullPath = `${BASE_URL}${fetchPath}`;
    const token = localStorage.getItem('token') || '';
    // http 开头直接使用
    if (/^https?:\/\//.test(fetchPath)) {
      fullPath = fetchPath;
    }
    let requestInit: RequestInit = {
      method,
      headers: {
        'content-type': ContentType.json,
        token,
      },
    };
    // get请求处理
    if (data && method === FetchMethod.get) {
      fullPath = `${fullPath}?${stringify(data)}`;
    }
    // post请求处理
    if (data && method === FetchMethod.post) {
      requestInit = {
        ...requestInit,
        body: JSON.stringify(data),
      };
    }
    return fetch(fullPath, requestInit)
      .then(checkStatus)
      .then(async (res: Response) => {
        return await res.json();
      })
      .then(checkCode)
      .catch((err: any) => {
        const tempErr = err.toString();
        if (tempErr) {
          message.error(tempErr);
        }
        return Promise.reject({
          code: -1,
          msg: tempErr,
        });
      });
  }

  public async post<T, S>(fetchPath: string, data: T): Promise<S> {
    return this.getValue(FetchMethod.post, fetchPath, data)
      .then((res) => Promise.resolve(res))
      .catch((err) => Promise.reject(err));
  }

  public async get<T, S>(fetchPath: string, data?: T): Promise<S> {
    return this.getValue(FetchMethod.get, fetchPath, data)
      .then((res) => Promise.resolve(res))
      .catch((err) => Promise.reject(err));
  }
}

export const Fetch = new Request();
