/** @format */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Layout, Menu } from 'antd';
import { FoldIcon, UnFoldIcon, LogoIcon } from '@/components/Icons';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
import TopBreadcrumb from '@/components/TopBreadcrumb';
import styles from './index.module.less';
import { useHistory, useLocation } from 'react-router-dom';
import { selectCollapsed, setCollapsed } from './layout.slice';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import routers, { Route } from '@routes/router';

interface MenuListItem {
  name: string;
  parentName?: string;
  path?: string;
  children?: MenuListItem[];
  icon?: React.ReactElement;
}

interface IProps {
  hideBreadcrumbPaths: (string | undefined)[];
}

const BasicLayout: React.FC<IProps> = ({ children, hideBreadcrumbPaths }) => {
  const location = useLocation();
  const defaultSelectedKeys = ['1'];
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] =
    useState<string[]>(defaultSelectedKeys);
  const history = useHistory();
  const dispatch = useDispatch();
  const collapsed = useSelector(selectCollapsed);

  const toggle = () => {
    dispatch(setCollapsed(!collapsed));
  };

  // 将路由配置转换成菜单结构
  const getMenuList = (menuList: MenuListItem[], routers: Route[]) => {
    routers.forEach((router) => {
      if (router.showInMenu) {
        const Icon = router.icon;
        const menuItem: MenuListItem = {
          name: router.name || '',
          path: router.path,
        };
        if (Icon) {
          menuItem.icon = <Icon style={{ fontSize: '16px' }} />;
        }
        menuList.push(menuItem);
        if (router.children?.find((item) => item.showInMenu)) {
          menuItem.children = [];
          getMenuList(menuItem.children, router.children);
        }
      }
    });
  };

  const menuList = useMemo(() => {
    const resList: MenuListItem[] = [];
    getMenuList(resList, routers);
    return resList;
  }, []);

  const flatterMenuList: MenuListItem[] = menuList.reduce((pre, cur) => {
    if (cur.children) {
      return [
        ...pre,
        ...cur.children.map((item) => ({ ...item, parentName: cur.name })),
      ];
    } else {
      return [...pre, cur];
    }
  }, [] as MenuListItem[]);

  const handleRoute = (path: string, name: string) => {
    setSelectedKeys([name]);
    history.push(path);
  };

  const onOpenChange = (openKeys: string[]) => {
    setOpenKeys(openKeys);
  };

  const logout = () => {
    localStorage.removeItem('token');
    history.replace('/login');
  };

  const hideBreadcrumb = useMemo(() => {
    return hideBreadcrumbPaths.find(
      (path: string | undefined) => path === location.pathname,
    );
  }, [location.pathname, hideBreadcrumbPaths]);

  useEffect(() => {
    const findMenu = flatterMenuList.find(
      (menu) => menu.path && location.pathname.includes(menu.path),
    );
    if (findMenu) {
      setSelectedKeys([findMenu.name]);
      if (findMenu.parentName) {
        setOpenKeys([findMenu.parentName]);
      }
    }
  }, [location.pathname]);

  return (
    <Layout className={styles.layout}>
      <Sider
        className={styles.sider}
        width={216}
        trigger={null}
        collapsible
        collapsed={collapsed}>
        {collapsed ? (
          <div
            className={styles.logo}
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              padding: '8px 0',
            }}>
            <LogoIcon style={{ fontSize: '40px' }} />
          </div>
        ) : (
          <div className={styles.logo}>易操练-智慧体训管理系统</div>
        )}
        <Menu
          className={styles.menu}
          mode='inline'
          theme='dark'
          onOpenChange={onOpenChange}
          defaultSelectedKeys={defaultSelectedKeys}
          selectedKeys={selectedKeys}
          openKeys={openKeys}>
          {menuList.map((item) => {
            if (item.children) {
              return (
                <SubMenu key={item.name} icon={item.icon} title={item.name}>
                  {item.children.map((menu) => (
                    <Menu.Item
                      key={menu.name}
                      onClick={() => handleRoute(menu.path || '/', menu.name)}>
                      {menu.name}
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            }
            return (
              <Menu.Item
                key={item.name}
                icon={item.icon}
                onClick={() => handleRoute(item.path || '/', item.name)}>
                {item.name}
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout>
        <Header className={styles.header}>
          <span className={styles.trigger}>
            {React.createElement(collapsed ? FoldIcon : UnFoldIcon, {
              onClick: toggle,
              style: { cursor: 'pointer', fontSize: '24px' },
            })}
          </span>
          <div className={styles.userInfo}>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key={'logout'} onClick={logout}>
                    退出登录
                  </Menu.Item>
                </Menu>
              }
              placement='bottom'
              arrow>
              <Button type='link' style={{ color: '#585858' }}>
                admin
              </Button>
            </Dropdown>
          </div>
        </Header>
        {!hideBreadcrumb ? <TopBreadcrumb /> : null}
        <Content
          className={classNames(
            styles.contentLayout,
            !hideBreadcrumb && styles.hasBreadcrumb,
          )}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default BasicLayout;
